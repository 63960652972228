export const externalLinks = {
  data: function() {
    return {
      eventNameAppend: "",
      links: {
        newLease: {
          link:
            "https://helpcentre.dataenergy.co.uk/hc/en-gb/requests/new?ticket_form_id=23278853796497",
          isDynamic: false,
          eventName: "externalNewLease",
          eventCategory: "externalLink"
        },
        newTenancy: {
          link:
            "https://helpcentre.dataenergy.co.uk/hc/en-gb/requests/new?ticket_form_id=23138875458065",
          isDynamic: false,
          eventName: "externalNewTenancy",
          eventCategory: "externalLink"
        },
        meterRead: {
          link: "https://dataenergy.co.uk/meterreads",
          isDynamic: true,
          eventName: "externalMeterRead",
          eventCategory: "externalLink"
        },
        contact: {
          link: "https://helpcentre.dataenergy.co.uk/hc/en-gb",
          isDynamic: false,
          eventName: "externalContact",
          eventCategory: "externalLink"
        },
        faq: {
          link: "https://helpcentre.dataenergy.co.uk/hc/en-gb",
          isDynamic: false,
          eventName: "externalFaq",
          eventCategory: "externalLink"
        }
      }
    };
  },
  methods: {
    externalLink(type) {
      if (!(type in this.links)) {
        return;
      }

      let eventName = this.links[type].eventName;
      if (this.eventNameAppend) {
        eventName += this.eventNameAppend;
      }

      this.$gtag.event(eventName, {
        event_category: this.links[type].eventCategory
      });

      let link = this.links[type].link;
      if (this.links[type].isDynamic) {
        link +=
          "?acnumber=" +
          this.account +
          "&buildingname=" +
          this.development +
          "&ourref=" +
          this.reference;
      }

      window.open(link);
    }
  }
};
