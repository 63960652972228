<template>
  <div class="row">
    <div class="col-md-12">
      <div class="block-items">
        <h2>Account Actions</h2>

        <b-button
          class="action button-item"
          size="sm"
          variant="info"
          @click="externalLink('meterRead')"
          >Submit Meter Reading</b-button
        >

        <span v-if="isLease">
          <b-button
            class="action button-item"
            size="sm"
            variant="info"
            @click="externalLink('newLease')"
            >Change of Leaseholder</b-button
          >

          <b-button
            class="action button-item"
            size="sm"
            variant="info"
            @click="externalLink('newTenancy')"
            >Change of Tenancy</b-button
          >
        </span>

        <div class="meter-note">
          <span>PLEASE NOTE:</span> Automatically recorded reads will be used by
          default, if available. When providing a meter read, the image
          submitted should contain the clear meter read, the whole meter and
          meter serial number if this is displayed.
        </div>

        <h2>Further Help</h2>

        <b-button
          class="action button-item"
          size="sm"
          variant="info"
          @click="externalLink('faq')"
          >FAQs</b-button
        >

        <b-button
          class="action button-item"
          size="sm"
          variant="info"
          @click="externalLink('contact')"
          >Contact Us</b-button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { externalLinks } from "@/components/mixins/external-links";

export default {
  name: "ActionsAndHelp",
  mixins: [externalLinks],
  props: {
    isLease: {
      type: Boolean,
      required: true
    },
    account: {
      type: String,
      required: true
    },
    development: {
      type: String,
      required: true
    },
    reference: {
      type: String,
      required: true
    },
    lastStatement: {
      type: String,
      default: null
    }
  },
  computed: {
    statementTitle() {
      if (this.lastStatement) {
        return "You requested a statement on " + this.lastStatement;
      }

      return "Request statement";
    }
  },
  methods: {
    updateParent() {
      this.$emit("dashboard_refresh");
    },

    requestStatement() {
      this.$swal({
        title: "Request a Statement",
        type: "info",
        input: "text",
        html:
          "<div><p>Request a billing statement from our customer services team</p></div>",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Request Statement",
        showLoaderOnConfirm: true,
        customClass: {
          input: "d-none"
        },
        preConfirm: () => {
          const id = this.reference;

          return this.$store
            .dispatch("dashboardCreditRequestStatement", { id })
            .then(() => {
              const title = "Statement Requested";
              const subtitle = "We have received your statement request";
              this.$store.dispatch("pushSuccess", { title, subtitle });

              this.updateParent();
            })
            .catch(err => {
              const title = "Invalid data";
              const subtitle = "There was a problem with your request: " + err;
              const samePage = true;
              this.$store.dispatch("pushError", { title, subtitle, samePage });
            });
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "../../../scss/dashboard.scss";

.block-items {
  text-align: left;
}

.button-item {
  margin-top: 10px;
  margin-left: 20px;
}

.meter-note {
  font-size: 0.9rem;
  margin: 10px 20px;
  color: #666;

  span {
    font-weight: bold;
  }
}
</style>
